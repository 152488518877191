"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var PhiaCircleIcon = function () { return (React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg", className: "h-4 w-4 rounded-[6px]" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.00163 1.28027C4.31973 1.28027 1.33496 4.26504 1.33496 7.94694C1.33496 11.6288 4.31973 14.6136 8.00163 14.6136C11.6835 14.6136 14.6683 11.6288 14.6683 7.94694C14.6683 4.26504 11.6835 1.28027 8.00163 1.28027ZM11.0374 6.2653C11.0374 5.07048 10.4556 4.22892 9.23998 4.22892C8.91506 4.22892 8.56036 4.28846 8.20499 4.38814C8.19374 4.39129 8.18312 4.3814 8.18553 4.36997L8.317 3.74723C8.32014 3.73235 8.30529 3.72007 8.29127 3.72594L7.16717 4.19625C7.15572 4.20104 7.14736 4.21116 7.14482 4.22331L7.00132 4.90888C6.99778 4.9258 6.98734 4.94046 6.97255 4.94941C6.72723 5.09798 6.48654 5.2655 6.24729 5.45198C6.23684 5.46013 6.23275 5.47403 6.2371 5.48655L6.28163 5.61483C6.28791 5.63292 6.30894 5.64105 6.32579 5.63195C6.51859 5.5278 6.70301 5.43479 6.8835 5.35291C6.89508 5.34766 6.90765 5.35785 6.90496 5.37028C6.46777 7.39083 6.11226 9.0349 5.66767 11.0936C5.6614 11.1226 5.65532 11.1508 5.64944 11.1781C5.49549 11.893 5.46774 12.0218 5.15215 12.0656C5.14249 12.0673 5.13475 12.0748 5.13316 12.0845L5.12077 12.1475C5.11888 12.159 5.12774 12.1694 5.13938 12.1694H6.90478C6.91401 12.1694 6.92189 12.1627 6.92338 12.1536L6.93295 12.0952C6.9347 12.0845 6.92693 12.0746 6.91627 12.073C6.57776 12.0467 6.63216 11.7902 6.75498 11.2112C6.76335 11.1717 6.77204 11.1307 6.78095 11.0882C7.03024 9.89844 7.04057 9.84282 7.04094 9.84072L7.04097 9.84055C7.04457 9.82357 7.06241 9.81379 7.07885 9.81939C7.29819 9.89411 7.62689 9.92247 7.79579 9.92247C9.75933 9.92247 11.0374 8.05232 11.0374 6.2653ZM7.9932 9.60092C9.06144 9.60092 9.96712 7.9692 9.96712 6.3588C9.96712 5.34061 9.57262 4.81027 8.74142 4.81027C8.53862 4.81027 8.33716 4.83996 8.09525 4.909C8.08422 4.91214 8.07587 4.92119 8.07353 4.93242L8.07353 4.93242L7.36981 8.2705C7.26099 8.78667 7.24072 9.60092 7.9932 9.60092Z", fill: "white" }))); };
exports.default = PhiaCircleIcon;
